<template>
    <div
        class="contact-form"
        id="contact-form"
        :class="{'form-open' : openForm}"
    >
        <div v-if="!showMessage" class="form-wrap">
            <div class="form-inside-wrapper">
                <div class="form-close" @click="closeForm">
                    <img src="images/svg/close.svg" alt="Close button">
                </div>
                <div class="form-heading textblack">
                    {{ contactsTranslations['request-form'] }}
                </div>
                <div class="interest">
                    <div class="question textblack">
                        {{ contactsTranslations['what-you-need'] }}<span>*</span>
                    </div>
                    <div class="selection">
                        <div
                            @click="form.service = 'Internetinė svetainė'"
                            class="service-item"
                            :class="[form.service === 'Internetinė svetainė' ? 'selected' : '' ]"
                        >
                            <img src="images/svg/website.svg" alt="Website">
                            <div class="name-of-service textblack">
                                {{ contactsTranslations['website'] }}
                            </div>
                        </div>
                        <div
                            @click="form.service = 'Elektroninė parduotuvė'"
                            class="service-item"
                            :class="[form.service === 'Elektroninė parduotuvė' ? 'selected' : '' ]"
                        >
                            <img src="images/svg/shopcart.svg" alt="E-Shop">
                            <div class="name-of-service textblack">
                                {{ contactsTranslations['e-shop'] }}
                            </div>
                        </div>
                        <div
                            @click="form.service = 'CRM - TVS'"
                            class="service-item"
                            :class="[form.service === 'CRM - TVS' ? 'selected' : '' ]"
                        >
                            <img src="images/svg/crm.svg" alt="TVS">
                            <div class="name-of-service textblack">
                                {{ contactsTranslations['crm'] }}
                            </div>
                        </div>

                        <div
                            @click="form.service = 'Mobiliosios aplikacijos'"
                            class="service-item"
                            :class="[form.service === 'Mobiliosios aplikacijos' ? 'selected' : '' ]"
                        >
                            <img src="images/svg/mobile_service_dark.svg" :alt="contactsTranslations['mobile_app']">
                            <div class="name-of-service textblack">
                                {{ contactsTranslations['mobile_app'] }}
                            </div>
                        </div>

                        <div
                            @click="form.service = 'Kita'"
                            class="service-item"
                            :class="[form.service === 'Kita' ? 'selected' : '' ]"
                        >
                            <img src="images/svg/other.svg" alt="Other services">
                            <div class="name-of-service textblack">
                                {{ contactsTranslations['other'] }}
                            </div>
                        </div>
                    </div>
                </div>
                <form @submit.prevent="submitForm">
                    <div class="form-group your-vission">
                        <label for="vission" class="textblack">{{ contactsTranslations['your-vission'] }}<span class="required-sign">*</span></label>
                        <input name="vission" v-validate="'required'" id="vission" type="text" class="form-control" v-model="form.vission">
                        <p v-if="!!vErrors.vission" class="error-message">{{ homeTranslations[vErrors.vission[0].split('.')[1]] }}</p>
                    </div>
                    <div class="contact-info textblack">
                        {{ contactsTranslations['contact-info'] }}<span class="required-sign">*</span>
                    </div>
                    <div class="form-row justify-content-between">
                        <div class="form-group custom-inputs">
                            <label for="fullName" class="textblack hidden-label"><span>full name</span></label>
                            <input v-validate="'required'" name="fullName" id="fullName" type="text" class="form-control" :placeholder="contactsTranslations['name']" v-model="form.fullName">
                            <p v-if="!!vErrors.fullName" class="error-message">{{ homeTranslations[vErrors.fullName[0].split('.')[1]] }}</p>
                        </div>
                        <div class="form-group custom-inputs">
                            <label for="comapany_name" class="textblack hidden-label"><span>company name</span></label>
                            <input type="text" class="form-control" name="comapany_name" id="comapany_name" :placeholder=" contactsTranslations['company']" v-model="form.company">
                        </div>
                    </div>
                    <div class="form-row justify-content-between">
                        <div class="form-group custom-inputs">
                            <label for="phone" class="textblack hidden-label"><span>phone</span></label>
                            <input v-validate="'required'" type="number" class="form-control" name="phone" id="phone" :placeholder="contactsTranslations['number'] + ' *'" v-model="form.phone">
                            <p v-if="!!vErrors.phone" class="error-message">{{ homeTranslations[vErrors.phone[0].split('.')[1]] }}</p>
                        </div>
                        <div class="form-group custom-inputs">
                            <label for="email" class="textblack hidden-label"><span>email</span></label>
                            <input v-validate="'required|email'" type="text" class="form-control" name="email" id="email" :placeholder="contactsTranslations['email'] + ' *'" v-model="form.email">
                            <p v-if="!!vErrors.email" class="error-message">{{ homeTranslations[vErrors.email[0].split('.')[1]] }}</p>
                        </div>
                    </div>
                    <div class="form-row info-source justify-content-between">
                        <div class="how-you-found textblack custom-inputs">
                            {{ contactsTranslations['how-you-find'] }}
                        </div>
                        <div class="form-group custom-inputs custom-selects">
                            <label for="inputState" class="textblack hidden-label"><span>recomendation</span></label>
                            <select id="inputState" name="inputState" class="form-control" v-model="form.findUs">
                                <option value="Rekomendacija">{{ contactsTranslations['recomendation'] }}</option>
                                <option value="Facebook">{{ contactsTranslations['facebook'] }}</option>
                                <option value="Google">{{ contactsTranslations['google'] }}</option>
                                <option value="Reklama">{{ contactsTranslations['advertising'] }}</option>
                                <option value="Kita">{{ contactsTranslations['other'] }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row last-row justify-content-between">
                        <div class="form-group">
                            <div class="choose-drink textblack">
                                {{ contactsTranslations['choose-drink'] }}
                            </div>
                            <div class="drinks d-flex">
                                <img
                                    @click="form.coffee = 1"
                                    src="images/cofe1.png"
                                    class="coffee"
                                    alt="Cup of coffee"
                                    :class="[form.coffee === 1 ? 'active-cofee' : '']"
                                >
                                <img
                                    @click="form.coffee = 2"
                                    src="images/cofe2.png"
                                    class="coffee"
                                    alt="Cup of coffee"
                                    :class="[form.coffee === 2 ? 'active-cofee' : '']"
                                >
                                <img
                                    @click="form.coffee = 3"
                                    src="images/cofe3.png"
                                    class="coffee"
                                    alt="Cup of coffee"
                                    :class="[form.coffee === 3 ? 'active-cofee' : '']"
                                >
                                <img
                                    @click="form.coffee = 4"
                                    src="images/cofe4.png"
                                    class="coffee"
                                    alt="Cup of coffee"
                                    :class="[form.coffee === 4 ? 'active-cofee' : '']"
                                >
                            </div>
                        </div>
                        <div class="form-group d-flex justify-content-end">
                            <span class="submit-wrapper">
                                <div class="box-row">
                                    <span class="privacy-policy">
                                        {{ contactsTranslations['agree-width'] }}
                                        <span>
                                            <a :href="getPrivacyPolicyLink()" target="_blank" rel="noopener">{{ contactsTranslations['privacy'] }}</a>
                                        </span>
                                        <p v-if="!!vErrors.accept_privacy" class="error-message">{{ homeTranslations[vErrors.accept_privacy[0].split('.')[1]] }} }}</p>
                                    </span>
                                    <div class="container-btn">
                                        <div class="check">
                                            <input v-validate="'required'" v-model="form.accept_privacy" id="check" type="checkbox"/>
                                            <label @click="acceptPrivacy" for="check">
                                                <div class="box"><i class="fa fa-check"></i></div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <button v-if="!loading" @click="submitEvent" type="submit" class="animated-button -submit-form">
                                    <span>{{ contactsTranslations['send'] }}</span>
                                    <div class="icon -arrow-right"></div>
                                </button>
                                <button class="animated-button  -submit-form" v-else type="submit"><img src="images/svg/loading.svg" alt="Loading gif"></button>
                            </span>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div v-else class="success-container">
            <div class="success-container-inner">
                <div class="success-icon">
                    <img src="images/svg/success-anim.svg" alt="succenss icon animation">
                </div>
                <div class="success-message" :class="{animate: showMessage}">
                    <div>{{ contactsTranslations['home.request_send'] }}</div>
                    <div>{{ contactsTranslations['home.will_get_back'] }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { bus } from '../bus';
import axios from 'axios'
import { setTimeout } from 'timers';

export default {
    props: {
        contactsTranslations: {
            type: Object,
            required: true
        },
        homeTranslations: {
            type: Object,
            required: true
        },
        locale: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            openForm: false,
            showMessage: false,
            loading: false,
            form: {
                service: 'Internetinė svetainė',
                coffee: 0,
                findUs: 'Rekomendacija',
                accept_privacy: false
            },
            vErrors: {},
            fullpage: false,
        }
    },

    methods: {
        submitEvent() {
            console.log('test');
            // Call the gtag_report_conversion function
            gtag_report_conversion();
        },
        getPrivacyPolicyLink() {
            return this.locale === 'lt' ? '/lt/privatumo-politika' : '/en/privacy-policy';
        },
        closeForm() {
            bus.$emit('open-form', false)
            this.openForm = false

            if(!this.fullpage) {
                document.getElementsByTagName("body")[0].style.overflowY = 'auto'
                document.getElementsByTagName("html")[0].style.overflowY = 'auto'
            }
            document.getElementById('contact-section').classList.remove('blured');
            document.getElementById('project-section').classList.remove('blured');
            document.getElementById('service-section').classList.remove('blured');
            document.getElementById('about-us-section').classList.remove('blured');
            document.getElementById('intro-section').classList.remove('blured');

            this.resetForm()
            setTimeout(() => { this.showMessage = false }, 1000)
        },

        resetForm() {
            this.form = {
                service: 'Internetinė svetainė',
                coffee: 0,
                findUs: 'Rekomendacija',
                accept_privacy: false,
            }

            this.vErrors = {}
        },

        async submitForm() {
            this.loading = true
            await axios.post('/api/send/email', this.form)
                .then(response => {
                    this.showEmailSended()
                    // this.closeForm()
                    this.resetForm()
                })
                .catch(err => {
                    this.vErrors = err.response.data.errors
                    this.loading = false
                });
        },

        showEmailSended() {
            this.loading = false
            this.showMessage = true
            this.resetForm()

            setTimeout(() => {
                this.closeForm()
            }, 6000)
        },

        acceptPrivacy() {
            console.log(this.form)
        },

        winWidth() {
            setInterval(() => {
                var w = window.innerWidth;
                if (w >= 992 && !this.fullpage) {
                    this.fullpage = false
                } else if (w < 992 && this.fullpage) {
                    this.fullpage = false
                }
            }, 100);
        }
    },

    created() {
        bus.$on('open-form', (data) => {
            this.openForm = data
        })
    },

    mounted() {
        this.winWidth()
    }
}
</script>


<style lang="scss" scoped>
.error-message {
    color: red;
    font-size: 12px;
    position: absolute;
}

.custom-selects {
    select {
        option {
            background: #f2f2f2;
        }

        option:checked {
            background-color: #e71332;
            color: #fff;
        }
    }
}

.hidden-label {
    opacity: 0;
    position: absolute;
    right: -100vh;
}
</style>
