import Vue from "vue";

require('slick-carousel');

require('./bootstrap');

window.Vue = require('vue');
// import KsVueScrollmagic from 'ks-vue-scrollmagic'
// Vue.use(KsVueScrollmagic)

import ContactForm from './components/ContactForm.vue'
import VeeValidate from 'vee-validate';
import VueFullPage from 'vue-fullpage.js'
import VueObserveVisibility from 'vue-observe-visibility'
import Vuex from 'vuex'


Vue.use(VeeValidate);
Vue.use(VueFullPage);
Vue.use(VueObserveVisibility);
Vue.use(Vuex)

// Load Layout Components
Vue.component('contact-form-vue', ContactForm)

Vue.config.productionTip = false

Vue.mixin({
    methods: {
        trans: (key, replace = {}) => {
            let translation = key.split('.').reduce((t, i) => t && t[i] ? t[i] : null, window.translations);

            if (translation !== null) {
                for (var placeholder in replace) {
                    translation = translation.replace(`:${placeholder}`, replace[placeholder]);
                }
            } else {
                console.error(`Translation key '${key}' not found.`);
                translation = '';
            }

            return translation;
        }
    }
});


const store = new Vuex.Store({
  state: {
    //
  },
    actions: {
      //
    },
  mutations: {
    //
  },
    getters: {
      //
    }
})

new Vue({
    el: '#app',
    components: {
        'contact-form-vue': ContactForm
    },
    store: store,
});
