// acceptCookies

var cookies = document.getElementById('acceptCookies')

cookies.addEventListener('click', () => {

    document.cookie = "accept_cookies=true";

    document.getElementById('cookiesCard').classList.add('-hidden')
})

$(document).ready(() => {
    let acceptCookie = document.cookie.match(/^(.*;)?\s*accept_cookies\s*=\s*[^;]+(.*)?$/)

    if (acceptCookie === null) {
        document.getElementById('cookiesCard').classList.remove('-hidden')
    }
})

// window.onload = function() {
//     console.log('testas')
//     let acceptCookie = document.cookie.match(/^(.*;)?\s*accept_cookies\s*=\s*[^;]+(.*)?$/)
//
//     if (acceptCookie === null) {
//         document.getElementById('cookiesCard').classList.remove('-hidden')
//     }
// };
