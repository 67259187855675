let preText = [
    'D', 'r', 'i', 'v', 'e', 'n', ' ', 'b', 'y', ' ', 'C', 'r', 'e', 'a', 't', 'i', 'v', 'i', 't', 'y'
];
let placeholderPositions = [
    66, 71, 74, 82, 90, 99, 102, 112, 120, 124, 135, 141, 149, 158, 163, 167, 175, 178, 183, 193
];
let interval = 20;
let timeout = 1800;
let preTimeout = 1000;
let text = '';
let index = 0;
let defX = 55;
let windowLoaded = false;
let animationFinished = true;

$(document).ready(function() {
    windowLoaded = true;

    if (animationFinished) {
        let isFirstVisit = sessionStorage.getItem('firstVisit') === null;

        if (isFirstVisit) {
            sessionStorage.setItem('firstVisit', 'true');
            $('#preeloader').css({ 'opacity': 1 }); // Show the preloader initially

            setTimeout(function() {
                $('#preeloader').css({ 'opacity': 0 });
                setTimeout(function() {
                    $('#preeloader').css({ 'display': 'none' });
                    if ($('html').width() <= 994) {
                        $('html').css({ 'overflow-y': 'auto' });
                    }
                }, 500);
            }, 3000); // Hide the preloader after 3 seconds on first visit
        } else {
            $('#preeloader').animate({ opacity: 0 }, 800, function() {
                setTimeout(function() {
                    $('#preeloader').css({ 'display': 'none' });
                    if ($('html').width() <= 994) {
                        $('html').css({ 'overflow-y': 'auto' });
                    }
                }, 500);
            });
        }
    }
});
