let hamburgerButton = document.getElementsByClassName('hamburger-menu')

if(hamburgerButton && hamburgerButton.length === 1) {
    let list = document.getElementById('mobileNav')
    let menutext = document.querySelector('.background-text')
    let x = document.getElementsByTagName("BODY")[0]

    document.querySelectorAll('.hamburger-menu').forEach(function(el){
        let burger = document.getElementById(el.id)
        let list = document.getElementById('mobileNav')
        let menutext = document.querySelector('.background-text')
        let x = document.getElementsByTagName("BODY")[0]
        let burgerSticky = document.getElementById("hamburger-sticky");

        if (burgerSticky) {
            burgerSticky.addEventListener('click', function() {
                openMenuSlide();
            });
        }

        burger.addEventListener('click', function() {
            openMenuSlide();
        });


    });

    document.querySelectorAll('.menu-list-button').forEach(function(button) {
        button.addEventListener('click', function(event) {
            closeMenuSlide();
        });
    });

    function openMenuSlide() {
        list.classList.toggle('show-navigation')
        menutext.classList.toggle('menu-text-appear')
        document.getElementsByTagName("BODY")[0].classList.toggle('off-overflow')
        document.getElementsByTagName("html")[0].classList.toggle('off-overflow')

        // list.style.top = window.pageYOffset + 'px'
        list.style.zIndex = '9999'
    }
    function closeMenuSlide() {
        list.classList.toggle('show-navigation')
        menutext.classList.toggle('menu-text-appear')
        document.getElementsByTagName("BODY")[0].classList.remove('off-overflow')
        document.getElementsByTagName("html")[0].classList.remove('off-overflow')

        // list.style.top = window.pageYOffset + 'px'
    }

}

let localeButton = document.getElementById('localeButton')

if (localeButton) {
    localeButton.addEventListener('click', () => {
        document.getElementById('navLocale').classList.toggle('active')
    })
}

let stickyLocaleButton = document.getElementById('stickyLocaleButton')

if (stickyLocaleButton) {
    stickyLocaleButton.addEventListener('click', () => {
        document.getElementById('stickyNavLocale').classList.toggle('active')
    })
}

let localeButtonProject = document.getElementById('localeButtonProject')

if (localeButtonProject) {
    localeButton.addEventListener('click', () => {
        document.getElementById('navLocaleProject').classList.toggle('active')
    })
}

let stickyLocaleButtonProject = document.getElementById('stickyLocaleButtonProject')

if (stickyLocaleButtonProject) {
    stickyLocaleButton.addEventListener('click', () => {
        document.getElementById('stickyNavLocaleProject').classList.toggle('active')
    })
}
