var serviceText = 2

let serviceId = 'service1'
let service = document.getElementById(serviceId)

if (service) {
  service.classList.add('animate-service')
}


window.onload = animateServicesText();

function animateServicesText() {
  setInterval(function(){
    let serviceId = 'service' + serviceText

    let service = document.getElementById(serviceId)

    if (service) {
      let oldServiceId = document.getElementsByClassName('animate-service')[0].getAttribute('id')
      let oldService = document.getElementById(oldServiceId)

      oldService.classList.remove('animate-service')
      service.classList.add('animate-service')

      if(serviceText >= 4) {
        serviceText = 1
      } else {
        serviceText = serviceText + 1
      }
    }


  },3000);
}
