var slickNext = document.getElementById('servicesList')

if (slickNext) {

    document.getElementById('arrowNextSlide').addEventListener('click', () => {
        $('.services-list').slick('slickNext')

        let slidesCount = 4

        let width = window.innerWidth

        let shownSlides = 1

        if (width >= 1800) {
            shownSlides = 3
        } else if (width > 1400) {
            shownSlides = 2
        } else if (width > 1300) {
            shownSlides = 2
        } else if (width > 800) {
            shownSlides = 2
        } else {
            shownSlides = 1
        }

        let currentSlide = $('.services-list').slick('slickCurrentSlide')
        let totalSlides = slidesCount - shownSlides


        if (currentSlide === totalSlides) {
            let nextButton = document.getElementById('arrowNextSlide')
            let prevButton = document.getElementById('arrowPrevSlide')
            let arrowRow = document.getElementById('arrowRow')

            nextButton.classList.add('-hidden')
            arrowRow.classList.add('-hidden-right')
            arrowRow.classList.remove('-show-all')
            arrowRow.classList.remove('-hidden-left')
            prevButton.classList.remove('-hidden')
        } else {
            let prevButton = document.getElementById('arrowPrevSlide')
            let arrowRow = document.getElementById('arrowRow')

            prevButton.classList.remove('-hidden')
            arrowRow.classList.add('-show-all')
            arrowRow.classList.remove('-hidden-left')
        }
    })

    document.getElementById('arrowPrevSlide').addEventListener('click', () => {
        $('.services-list').slick('slickPrev');

        let currentSlide = $('.services-list').slick('slickCurrentSlide')

        if (currentSlide === 0) {
            let arrowRow = document.getElementById('arrowRow')
            let prevButton = document.getElementById('arrowPrevSlide')
            let nextButton = document.getElementById('arrowNextSlide')

            prevButton.classList.add('-hidden')
            arrowRow.classList.remove('-hidden-right')
            arrowRow.classList.remove('-show-all')
            arrowRow.classList.add('-hidden-left')
            nextButton.classList.remove('-hidden')
        } else {
            let nextButton = document.getElementById('arrowNextSlide')
            let arrowRow = document.getElementById('arrowRow')

            nextButton.classList.remove('-hidden')
            arrowRow.classList.remove('-hidden-right')
            arrowRow.classList.remove('-hidden-left')
            arrowRow.classList.add('-show-all')
        }
    })
}
