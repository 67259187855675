var allProjectsPage = document.getElementById('allProjectsPage')

if (allProjectsPage) {


    window.onload = () => {
        let scrollPosition = window.pageYOffset
        getSectionPosition(scrollPosition)
    }

    window.addEventListener('scroll', function(e) {
        let scrollPosition = window.pageYOffset
        getSectionPosition(scrollPosition)
    });
}

// get all section positions
function getSectionPosition(scrollPosition) {
    let x = document.getElementById("slideContainer").querySelectorAll(".jscontainer")
    let thirdFromEndIndex = x.length > 2 ? x.length - 3 : null;

    for (let index = 0; index < x.length; index++) {
        const el = x[index];

        const elPosition = el.getBoundingClientRect().top;

        if (elPosition < (el.clientHeight - 50) && elPosition > 0) {
            animateSection(index + 1)

            if (index === thirdFromEndIndex && window.innerWidth <= 1199) {
                setTimeout(() => {
                    animateSection(index + 2);
                }, 1000);

                setTimeout(() => {
                    animateSection(index + 3);
                }, 1500);
            }
        }
    }

}

// animate section when is  scroll position posicion
function animateSection(id) {
    let slideId = 'slide' + id

    if (id % 2 === 0 ) {
        document.getElementById(slideId).classList.add('show-info-reverse')
    } else {
        document.getElementById(slideId).classList.add('show-info-normal')
    }

}
