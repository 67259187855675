require('slick-carousel');
require('./imports/preloader');
require('./imports/acceptCookies');
require('./imports/paralax');
require('./imports/navigation');
require('./imports/contact');
require('./imports/slickButton');
require('./imports/acceptCookies');
require('./imports/rotateProjects');
require('./imports/allProjectsAnimate');
require('./imports/animateIntroServices');
require('./imports/map');

import { bus } from './bus.js';

/* Sticky menu */
$(document).ready(function() {
    $('#formButton, #introFormButton').click(function(){
        event.preventDefault();

        bus.$emit('open-form', true);

        blurSections();
    });

    function blurSections() {
        document.getElementsByTagName("body")[0].style.overflowY = 'scroll';
        document.getElementsByTagName("html")[0].style.overflowY = 'hidden';
        document.getElementById('contact-section').classList.add('blured');
        document.getElementById('project-section').classList.add('blured');
        document.getElementById('service-section').classList.add('blured');
        document.getElementById('about-us-section').classList.add('blured');
        document.getElementById('intro-section').classList.add('blured');
    }

    var $mainDesktopMenu = $('.sticky-menu');
    var headerHeight = $mainDesktopMenu.outerHeight();
    var lastScrollTop = 0;

    $(window).on('scroll', function() {
        /* Sticky menu */
        var scrollPosition = $(this).scrollTop();

        if (scrollPosition > lastScrollTop && scrollPosition > headerHeight) {
            $mainDesktopMenu.addClass('sticky slide-down');
            $mainDesktopMenu.removeClass('slide-up');
        } else {
            $mainDesktopMenu.addClass('slide-up');
            $mainDesktopMenu.removeClass('sticky slide-down');
        }

        lastScrollTop = scrollPosition;

        /* Back to top */
        if ($(this).scrollTop() > 100) {
            $('#scroll').fadeIn();
        } else {
            $('#scroll').fadeOut();
        }
    });

    $('#scroll').click(function(){
        $("html, body").animate({ scrollTop: 0 }, 600);
        return false;
    });
});

/* Smooth anchor scroll */
$(document).ready(function() {
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
            location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: target.offset().top
                }, 800); // Adjust the animation speed (in milliseconds) as needed
                return false;
            }
        }
    });
});

/* Animated counters */
const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.3 // Adjust this threshold value as needed
};

const sections = document.querySelectorAll('.counter-number');

const animateNumber = (element, endValue, addPlus) => {
    const countTicks = 20;
    const countSpeed = 50;
    const randomNumbers = [endValue];

    for (let i = 0; i < countTicks - 1; i++) {
        randomNumbers.unshift(
            Math.floor(Math.random() * (endValue - 0 + 1))
        );
    }

    randomNumbers.sort((a, b) => { return a - b; });

    let x = 0;
    const interval = setInterval(() => {
        element.textContent = randomNumbers.shift();

        if (addPlus && element.textContent === String(endValue) && endValue > 0) {
            element.textContent += '+';
        }

        if (++x === countTicks) {
            clearInterval(interval);
        }
    }, countSpeed);
};

const animateNumbersWhenInView = (entries) => {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            sections.forEach(section => {
                if (entry.target === section) {
                    const endValue = parseInt(section.dataset.endValue);
                    const addPlus = section.dataset.plus === 'true';
                    animateNumber(section, endValue, addPlus);
                }
            });
        }
    });
};

const observer = new IntersectionObserver(animateNumbersWhenInView, options);

sections.forEach(section => {
    observer.observe(section);
});



$('.variable-width').slick({
    dots: true,
    infinite: true,
    speed: 500,
    centerMode: true,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToScroll: 1,
    // lazyLoad: 'ondemand',
    // slidesToShow: 1,
});

$('.preject-slick').slick({
  infinite: false,
  slidesToShow: 5,
  slidesToScroll: 1,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 2000,
  // variableWidth: true,
  speed: 1000,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: false,
      }
    },
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
      }
    },
    {
      breakpoint: 330,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
      }
    },
  ]
});

$('.services-list').slick({
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    responsive: [
        {
          breakpoint: 1800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
          }
        },
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
          }
        },
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
          }
        },
      ]
});
